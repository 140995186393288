import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const transactionToken = sessionStorage.getItem("transactionToken");
    const accessToken = sessionStorage.getItem("access_token");

    if (accessToken && transactionToken) {
      return true;
    }

    this.authService.logout();
    return false;
  }
}
