import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { SHA256 } from 'crypto-js';
import { Buffer } from 'buffer';

/**
 * Esta dependencia se deja comentada
 * porque no se requiere crear el 
 * client_assertion para frontend.
 * @experimental
 */
/*import { SignJWT, importJWK } from 'jose' Esta*/

@Injectable({
  providedIn: 'root'
})
export class UtilsService {



  public codeVerify(str: any) {
    return str.toString('base64')
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=/g, '');

  }


  public codeChallenge(varString: string) {

    let varReturn = SHA256(varString).toString(CryptoJS.enc.Base64url);
    return varReturn;
  }


  public generateTokenHeader(bearerToken: string) {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": 'Bearer ' + bearerToken
    });

    return headers;
  }

  public validateIdToken(idToken: any): any {

    const tokenHeader = JSON.parse(Buffer.from(idToken.split('.')[0], 'base64').toString());
    const tokenBody = JSON.parse(Buffer.from(idToken.split('.')[1], 'base64').toString());

    const tokenDecode = { ...tokenHeader, ...tokenBody }

    const { given_name, family_name } = tokenDecode

    if (!given_name || !family_name) return false
    else return true
  }

  public addUnderScore(nationalId: string) {
    const lastCharacter = nationalId.slice(-1)
    const idWithoutTheLastCharacter = nationalId.substring(0, nationalId.length - 1)
    return `${idWithoutTheLastCharacter}-${lastCharacter}`
  }


}
