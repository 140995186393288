<!--begin::Signin header-->
<header class="signin__header" *ngIf="!pageNotFound">
  <app-svg-curve></app-svg-curve>
  <div class="container">
    <app-logo type="principal"></app-logo>
    <h2><strong>Pluxee</strong> en <strong>Uber Eats</strong></h2>
    <h2> Redirigiendo, por favor espera... </h2>
  </div>
</header>
<!--begin::Signin body-->
<div class="signin__body container">
  
  <div *ngIf="pageNotFound" class="page-not-found">
    <div class="content">
      <app-logo type="principal"></app-logo>
      <h1>404</h1>
      <p>La página solicitada no existe.</p>
    </div>
  </div>
</div>
<!--end::Signin-->