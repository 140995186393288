import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SigninComponent } from './features/signin/signin.component';
import { CardSelectionComponent } from './features/card-selection/card-selection.component';
import { CardHelpComponent } from './features/card-help/card-help.component';
import { ConfirmationComponent } from './features/confirmation/confirmation.component';

import { AuthGuard } from 'src/app/core/guards/auth.guard';


const routes: Routes = [
  { path: '', component:  SigninComponent },
  {
    path: 'login',
    component: SigninComponent,
  },
  {
    path: 'signin',
    component: SigninComponent,
  },
  {
    path: 'card-selection',
    component: CardSelectionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'card-help',
    component: CardHelpComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'confirmation/:id',
    component: ConfirmationComponent,
    canActivate: [AuthGuard],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

