import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-card-selector',
  templateUrl: './card-selector.component.html',
  styleUrls: ['./card-selector.component.scss']
})
export class CardSelectorComponent implements OnInit {

  @Input() cards: any = [];
  @Input() selected: number | null = null;
  @Output() cardChange = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  cardChecked(cardPan: number){
    this.selected = cardPan;
    this.cardChange.emit(cardPan?.toString());
  }
}
