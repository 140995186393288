import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit {
  @Input() type: 'principal' | 'white' = 'principal';
  images: any = [
    {
      'type': 'principal',
      'src': 'assets/images/plx-logo.svg'
    },
    {
      'type': 'white',
      'src': 'assets/images/plx-logo-white.svg'
    }
  ];
  src = null;

  constructor() { }

  ngOnInit(): void {
    this.src = this.type === null ?
      this.images[0].src :
      this.images.filter( (img: any) => {
        return img.type === this.type
      })[0].src;
  }

}
