import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { User } from 'src/app/core/models/user.model';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment} from 'src/environments/environment';
import { UtilsService } from 'src/app/core/services/utils.service';
import cryptoRandomString from 'crypto-random-string';

const BASE_URL = environment.apiUrl;
@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {

  public currentUser$!: Observable<User>;
  protected currentUserSubject!: BehaviorSubject<User>;

  private unsubscribe: Subscription[] = [];
  private authLocalStorage = `sodexo-uber-payment`;

  hasError: boolean = false;
  returnUrl: string = '';

  constructor(
    private router: Router,
    private http: HttpClient,
    private utils: UtilsService
  ) {

  }

  get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: User) {
    this.currentUserSubject.next(user);
  }

  login(loginID: string, password: string, transactionToken: string):Observable<User> {
    const auth = this.http.post<User>(BASE_URL + '/user/login', { loginID, password, transactionToken })
    return auth    
  }

  logout() {
    localStorage.removeItem(this.authLocalStorage);
    this.router.navigate(['/signin'], {
      queryParams: {},
    });
  }

  getAuthFromLocalStorage() {
    try {
      const auth = localStorage.getItem(this.authLocalStorage);
      if (typeof auth === 'string') {
        return JSON.parse(auth);
      }
      return auth;
    } catch (error) {
      return undefined;
    }
  }
  setAccessTokenInLocalStorage(accessToken: string): boolean {
    try {
      const authData = this.getAuthFromLocalStorage() || {};
      authData.accessToken = accessToken;
      const authString = JSON.stringify(authData);
      localStorage.setItem(this.authLocalStorage, authString);
      return true;
    } catch (error) {
      console.error('Error al guardar el accessToken en localStorage', error);
      return false;
    }
  }
  setAuthFromLocalStorage(auth: any): boolean {
    if (auth) {
      localStorage.setItem(this.authLocalStorage, auth);
      return true;
    }
    return false;
  }
  public generateUrlAuth() {
    let codeVer = this.utils.codeVerify(cryptoRandomString({ length: 43, type: 'url-safe' }));
    let code = this.utils.codeChallenge(codeVer);

    const platform = sessionStorage.getItem('platform')

    let url = new URL(environment.oidc.urlAuthTokenConnect);
    url.searchParams.append("client_id", environment.oidc.clientIdCardEnrollment);
    url.searchParams.append("response_type", "code");
    url.searchParams.append("redirect_uri", environment.oidc.redirectAuthConnect);
    url.searchParams.append("code_challenge", code);
    url.searchParams.append("code_challenge_method", "S256");
    url.searchParams.append("state", this.utils.codeChallenge(cryptoRandomString({ length: 32 })));
    url.searchParams.append("resource", environment.oidc.resourceAuthConnect);
    url.searchParams.append("scope", "openid email phone profile");

    if (platform === 'junaeb') url.searchParams.append("prompt", "select_account")

    sessionStorage.setItem("codeVeri", codeVer);

    return url.href;

  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
