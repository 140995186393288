import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { SdxConnectService } from 'src/app/core/services/sdx-connect.service';
import { UtilsService } from 'src/app/core/services/utils.service';


@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit, OnDestroy {

  defaultAuth: any = {
    email: '',
    password: '',
  };
  loading: boolean = false;
  hasError: boolean = false;
  transactionToken: string = '';
  pageNotFound: boolean = false;
  completeFlow : boolean = false;
  code: string = "";
  state: string = "";
  private unsubscribe: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private sdxConnectService: SdxConnectService,
    private utils : UtilsService,
    private router: Router,
    private authService: AuthService

  ) {

  }
  ngOnInit(): void {
    this.completeFlow = sessionStorage.getItem("completeFlow") === "true";
    this.pageNotFound = false; // Inicializar pageNotFound
  
    this.route.queryParams.subscribe(params => {
      this.transactionToken = params['token'];
      this.code = params['code'];
      this.state = params['state'];
  
      if (this.completeFlow) {
        this.authService.logout();
        this.completeFlow = false;
        sessionStorage.setItem("completeFlow", this.completeFlow.toString());
      }else{
        if (this.transactionToken) {
          sessionStorage.setItem("transactionToken", this.transactionToken);
          window.location.href = this.sdxConnectService.generateUrlAuth();
        } else if (this.code && this.state) {
          this.sdxConnectService.tokenGenerate(this.code, this.state).subscribe(
            responseTokenGenerate => {
              const { id_token, access_token } = responseTokenGenerate;
              if (!this.utils.validateIdToken(id_token)) {
                this.pageNotFound = true;
              } else {
                sessionStorage.setItem("access_token", access_token);
                this.router.navigate(['card-selection']);
              }
            }
          );
        } else {
          this.pageNotFound = true;
        }
      }  
    });
  }
  
  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
