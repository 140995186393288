import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SigninComponent } from './features/signin/signin.component';
import { CardSelectorComponent } from './features/_components/card-selector/card-selector.component';
import { ConfirmationComponent } from './features/confirmation/confirmation.component';
import { CardSelectionComponent } from './features/card-selection/card-selection.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CodeInputModule } from 'angular-code-input';
import { AngularSvgIconModule } from 'angular-svg-icon';


import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { LogoComponent } from './features/_components/logo/logo.component';
import { SvgCurveComponent } from './features/_components/svg-curve/svg-curve.component';
import { FormHeaderComponent } from './features/_components/form-header/form-header.component';
import { ButtonComponent } from './features/_components/button/button.component';
import { CardHelpComponent } from './features/card-help/card-help.component';

@NgModule({
  declarations: [
    AppComponent,
    SigninComponent,
    CardSelectorComponent,
    ConfirmationComponent,
    CardSelectionComponent,
    LogoComponent,
    SvgCurveComponent,
    FormHeaderComponent,
    ButtonComponent,
    CardHelpComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AngularSvgIconModule.forRoot(),
    CodeInputModule.forRoot({
      codeLength: 4,
      isCharsCode: false,
      isCodeHidden: true,
      initialFocusField: 0,
      code: 'number'
    })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
