export const environment = {
  production: false,
  apiUrl: 'https://tarjeta.vinculacion.consumidores.dev.pluxee.cl',


  oidc: {
    clientIdCardEnrollment: '3f5a973e-0966-4ef0-aaf9-a6a85887f623',
    redirectAuthConnect: 'https://tarjeta.vinculacion.consumidores.dev.pluxee.cl/login',
    resourceAuthConnect: 'https://api.servicios.dev.pluxee.cl/connect/',
    sdxConnectTokenAuth: 'https://connect.uat.pluxee.app/op/oidc/token',
    urlAuthTokenConnect:'https://connect.uat.pluxee.app/op/oidc/auth',


  },


};
