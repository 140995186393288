<!-- Header -->
<header class="card-help__header">
  <app-svg-curve></app-svg-curve>
  <div class="container">
    <app-logo></app-logo>
  </div>
</header>

<div class="card-help__body container">
  <a [routerLink]="['/card-selection']" class="btn btn--kind-secondary btn--aspect-text btn--icon btn--icon-position-left">
    <svg-icon src="assets/images/icons/plx-ic-chevronLeft.svg"></svg-icon><span class="btn__label">Volver a seleccionar tarjeta</span>
  </a>
  <app-form-header title="Encuentra tu número de tarjeta"></app-form-header>
  <ol>
    <li>
      <span>Ingresa a tu perfil en la App Pluxee.</span>
      <img src="assets/images/tutorial-paso-uno.png" alt="Pantallazo de perfil en portal Pluxee">
    </li>
    <li>
      <span>Revisa la sección Mis Tarjetas.</span>
      <img src="assets/images/tutorial-paso-dos.png" alt="Pantallazo de acceso a sección Mis Tarjetas, en portal Pluxee">
    </li>
  </ol>
</div>
