<!-- Header -->
<header class="confirmation__header">
  <div class="container">
    <app-logo type="white"></app-logo>
  </div>
</header>

<div class="confirmation__body container">
  <h1>¡Listo!</h1>
  <p>Ya puedes comenzar a usar tu servicio <strong>Pluxee como medio de pago en Uber.</strong></p>
  <p>Te <strong>hemos enviado un correo electrónico</strong> con la confirmación.</p>
  <div class="form-group">
    <button app-button (click)="close()">
      Volver a Uber Eats
    </button>
  </div>
</div>
