import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment as env } from 'src/environments/environment';

const BASE_URL = env.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private httpClient: HttpClient) { }

  public get(path: string, token: string, params: HttpParams = new HttpParams()): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8'
    });
    return this.httpClient.get(BASE_URL + path, { params, headers })
      .pipe(catchError(this.formatErrors));
  }

  download(path: string, token: string, params: HttpParams = new HttpParams()): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8',
      'Access-Control-Expose-Headers': 'Content-Disposition'
    });
    return this.httpClient.get(BASE_URL + path, { headers, responseType: 'blob', params })
      .pipe(catchError(this.formatErrors));
  }

  public put(path: string, body: object = {}, token: string): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8'
    });

    return this.httpClient
      .put(BASE_URL + path, JSON.stringify(body), {headers})
      .pipe(catchError(this.formatErrors));
  }
  public post(path: string, token: string, pan: number): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8'
    });

    return this.httpClient
      .post<any>(BASE_URL + path, JSON.stringify({"pan": pan}), {
        headers,
        observe: 'response'
      })
      .pipe(catchError(this.formatErrors));
  }

  public postPinValidation(path: string, token: string, pin: string): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8'
    });

    return this.httpClient
      .post<any>(BASE_URL + path, JSON.stringify({"pin": pin}), {
        headers,
        observe: 'response'
      })
      .pipe(catchError(this.formatErrors));
  }
  public postconfirmCardEnrollment(path: string, token: string, pan: number, transactionToken : string): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8'
    });

    return this.httpClient
      .post<any>(BASE_URL + path, JSON.stringify({"pan": pan, "transactionId" : transactionToken}), {
        headers,
        observe: 'response'
      })
      .pipe(catchError(this.formatErrors));
  }
  public delete(path: string, token: string): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8'
    });
    return this.httpClient.delete(BASE_URL + path, {headers}).pipe(catchError(this.formatErrors));
  }

  public formatErrors(error: any): Observable<any> {
    return throwError(error);
  }

  public postMultipartFile(path: string, body: any): Observable<any> {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'multipart/form-data');
    headers.set('Accept', 'application/json');
    return this.httpClient
      .post<any>(BASE_URL + path, body, {
        headers,
        observe: 'response'
      })
      .pipe(catchError(this.formatErrors));
  }

}
