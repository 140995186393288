import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CodeInputComponent } from 'angular-code-input';
import { Card } from 'src/app/core/models/card.model';

import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-card-selection',
  templateUrl: './card-selection.component.html',
  styleUrls: ['./card-selection.component.scss']
})

export class CardSelectionComponent implements OnInit, OnDestroy {
  private unsubscribe: Subscription[] = [];
  step = 0; // 0 - selector de tarjeta, 1 - pincode

  cards = [];
  cardSelectionForm: FormGroup = this.initForm();
  loading: boolean = false;
  hasError: boolean = false;
  validated = false;
  isValid = false;
  @ViewChild('codeInput') codeInput !: CodeInputComponent;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
  ) {

  }

  ngOnInit(): void {
    this.getCards()
  }

  initForm() {
    return this.fb.group({
      cardNumber: [
        '',
        Validators.compose([
          Validators.required
        ]),
      ],
      pincode: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(4)
        ]),
      ],
    });
  }

  get f() {
    return this.cardSelectionForm.controls;
  }

  submit() {
    this.loading = true;
    const checkPin = this.userService.pinValidation(this.f.pincode.value).subscribe(
      (r) => {
        this.validated = true;
        this.isValid = r.body.response;
        if ( this.isValid ) {
          this.hasError = false;
          const loginSubscr = this.userService.setCard(this.f.cardNumber.value).subscribe(
            (response) => {
              this.loading = false;
              if (response) {
                this.router.navigate(['/confirmation/' + this.f.cardNumber.value], );
              } else {
                this.hasError = false;
              }
            }, error => {
              this.loading = false;
              this.hasError = true;
            }
          );
          this.unsubscribe.push(loginSubscr);
        } else {
          this.loading = false;
          this.f.pincode.setValue('');
          this.codeInput.reset();
          this.codeInput.focusOnField(0);
        }
      }, error => {
        this.loading = false;
        this.validated = true;
        this.f.pincode.setValue('');
        this.codeInput.reset();
        this.codeInput.focusOnField(0);
      }
    );
    this.unsubscribe.push(checkPin);
  }

  getCards() {
    const getCards = this.userService.getCards().subscribe(
      (cards: [Card]) => {
        const _cards: any = [];
        if(cards.length > 0){
          cards.forEach((card: Card) => {
            _cards.push({
              pan: card.pan,
              company: card.cliRazonSocial
            });
          });
          this.cards = _cards;
        }
        else{
          this.hasError = true
        }
        
      }, error => {
        console.log(error)
      }
    )
    this.unsubscribe.push(getCards);
  }

  updateCardPan(cardPan:string) {
    this.f.cardNumber.setValue(cardPan);
  }

  updatePincode(pincode:string) {
    this.f.pincode.setValue(pincode);
    this.isValid = false;
    this.validated = false;
  }

  setStep(s: number) {
    this.step = s;
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

}
