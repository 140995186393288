<div class="card-selectors" *ngFor='let card of cards; let i = index'>
  <label class="card-selector" [ngClass]="{ 'card-selector--selected': card.pan === selected }">
    <div class="card-selector__body">
      <input class="card-selector__input" type="radio" [checked]="card.pan === selected" [value]="card.pan" (change)="cardChecked(card.pan)">
      <div class="card-selector__index" *ngIf="cards.length > 1">Tarjeta {{ i + 1 }}</div>
      <div class="card-selector__company">{{ card.company }}</div>
      <div class="card-selector__number">Nº {{ card.pan }}</div>
    </div>
    <!-- <app-svg-curve></app-svg-curve> -->
  </label>
</div>
