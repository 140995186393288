import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { AuthService } from 'src/app/core/services/auth.service';

@Injectable({ providedIn: 'root' })

export class UserService {

    token: string | null;
    transactionToken : string | null;
    constructor(private apiService: ApiService, private authenticationService: AuthService) {        

        this.transactionToken = sessionStorage.getItem("transactionToken");
        this.token = sessionStorage.getItem("access_token");

    }

    getCards() {
        if (this.token === null) {
            // Handle the error or return
            throw new Error("No access token available");
        }
        return this.apiService.get('/user/findActiveCardsByBeneficiary', this.token);
        }

    pinValidation(pin:string) {
        if (this.token === null) {
            // Handle the error or return
            throw new Error("No access token available");
        }
        return this.apiService.postPinValidation('/user/validatePin', this.token, pin);
    
    
    }

    setCard(pan: number) {
        if (this.token === null || this.transactionToken === null) {
            throw new Error("Tokens are not available");
        }
        return this.apiService.postconfirmCardEnrollment('/user/confirmCardEnrollment', this.token, pan, this.transactionToken);
        }
}
