import { TokenAuthResponse } from './../interface/tokenAuthFlowInterface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UtilsService } from './utils.service';
import cryptoRandomString from 'crypto-random-string';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';




@Injectable({
  providedIn: 'root'
})
export class SdxConnectService {


  headers = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded'
  });

  constructor(private _utils: UtilsService, private http: HttpClient) {
  }

  public generateUrlAuth() {
    let codeVer = this._utils.codeVerify(cryptoRandomString({ length: 43, type: 'url-safe' }));
    let code = this._utils.codeChallenge(codeVer);

    const platform = sessionStorage.getItem('platform')

    let url = new URL(environment.oidc.urlAuthTokenConnect);
    url.searchParams.append("client_id", environment.oidc.clientIdCardEnrollment);
    url.searchParams.append("response_type", "code");
    url.searchParams.append("redirect_uri", environment.oidc.redirectAuthConnect);
    url.searchParams.append("code_challenge", code);
    url.searchParams.append("code_challenge_method", "S256");
    url.searchParams.append("state", this._utils.codeChallenge(cryptoRandomString({ length: 32 })));
    url.searchParams.append("resource", environment.oidc.resourceAuthConnect);
    url.searchParams.append("scope", "openid email phone profile");

    if (platform === 'junaeb') url.searchParams.append("prompt", "select_account")

    sessionStorage.setItem("codeVeri", codeVer);

    return url.href;

  }

  public tokenGenerate(code: string, state: string): Observable<TokenAuthResponse> {

    let codeVerify = sessionStorage.getItem("codeVeri") !== null ? sessionStorage.getItem("codeVeri") : "";

    let body = new URLSearchParams();
    body.set('grant_type', "authorization_code");
    body.set('code', code);
    body.set('state', state);
    body.set('redirect_uri', environment.oidc.redirectAuthConnect);
    body.set('client_id', environment.oidc.clientIdCardEnrollment);
    body.set('code_verifier', codeVerify !== null ? codeVerify : "");
    body.set('scope', "openid email phone profile");
    body.set('resource', environment.oidc.resourceAuthConnect);

    return this.http
      .post<TokenAuthResponse>(environment.oidc.sdxConnectTokenAuth, body.toString(), { headers: this.headers })
  }

  public refreshToken(refreshToken: string): Observable<TokenAuthResponse> {
    let body = new URLSearchParams();
    body.set('client_id', environment.oidc.clientIdCardEnrollment);
    body.set('grant_type', "refresh_token");
    body.set('scope', "openid email phone profile");
    body.set('refresh_token', refreshToken);
    body.set('resource', environment.oidc.resourceAuthConnect);

    return this.http.post<TokenAuthResponse>(environment.oidc.sdxConnectTokenAuth, body.toString(), { headers: this.headers })

  }

}
