import { Component, Input, OnInit, HostBinding } from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: '[app-button]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input() class = '';
  @Input() @HostBinding('disabled') disabled = false;
  @Input() styling: 'primary' | 'secondary' | 'link' = 'primary';
  @Input() size: '' | 'sm' | 'lg' = '';
  @Input() icon: string | null = null;
  @Input() loading = false;

  constructor() { }

  ngOnInit() {
    this.class = 'btn' + ( this.class !== '' ? ' ' + this.class : '' );
  }

  @HostBinding('class')
  get hostClasses(): string {
    return [
      this.class, // include our new one
      this.size === 'lg' ? 'btn-lg' : '',
      this.size === 'sm' ? 'btn-sm' : '',
      this.disabled ? 'btn--kind-primary btn--aspect-filled btn-outline-default' : '',
      this.styling === 'primary' && ! this.disabled ? 'btn--kind-primary btn--aspect-filled' : '',
      this.styling === 'secondary' && ! this.disabled ? 'btn--kind-secondary' : '',
      this.styling === 'link' && ! this.disabled ? 'btn--kind-secondary btn--aspect-text' : '',
      this.loading ? 'btn--icon' : '',
    ].join(' ');
  }

}
